import tuto from "@images/Processing-amico.svg";
import guides from "@images/Webinar-amico.svg";
import advanced from "@images/Development-amico.svg";

import { Footer, Layout } from "@components";
import { RootSelector, RootSelectorItem } from "@components/RootSelector";

import "@styles/Index.scss";

const IndexPage = () => {
  return (
    <Layout title="Accueil">
      <RootSelector
        title="Welcome to the Dotter.science documentation."
        subtitle="How can we help you?"
      >
        <RootSelectorItem src={tuto} alt="Tutorial" href="/en/tutorial">
          Tutorials
        </RootSelectorItem>
        <RootSelectorItem src={guides} alt="Guides" href="/en/guides">
          Guides
        </RootSelectorItem>
        <RootSelectorItem
          src={advanced}
          alt="Advanced features"
          href="/en/advanced"
        >
          <span>Advanced</span>
          <span>Features</span>
        </RootSelectorItem>
      </RootSelector>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
